import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';

const IndexPage = () => (
  <div
    style={{
      minHeight: '100vh',
      backgroundImage: 'url(https://res.cloudinary.com/ma-b/image/upload/v1583012333/vlcsnap-00002_rsuk8d.webp)',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    }}
  >
    <CssBaseline />
  </div>
);

export default IndexPage;
